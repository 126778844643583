import { useDeviceType } from '@hotelplan/libs.context.device-type';
import { useFeatureToggle } from '@hotelplan/libs.feature-toggle';
import { FeatureList } from 'config/pageConfig.constants';
import { useGetLastSeenHotelsQuery } from 'graphql/last-seen-hotels/LastSeenHotels.generated';
import { LastSeenHotelsVariants } from './lastSeenHotels.types';

export const useLastSeenHotels = (isPdpOrl?: boolean) => {
  const { mobile } = useDeviceType();
  const { getVariant } = useFeatureToggle();

  const isShowLastSeenHotels =
    getVariant(FeatureList.MF_LAST_SEEN_HOTELS).name ===
    LastSeenHotelsVariants.SHOW_LAST_SEEN_HOTELS;

  const resultsPerPage = mobile ? 4 : 3;

  const { data } = useGetLastSeenHotelsQuery({
    variables: {
      resultsPerPage,
    },
    nextFetchPolicy: isPdpOrl ? 'cache-and-network' : 'cache-first',
    skip: !isShowLastSeenHotels,
  });

  return data?.history?.lastSeenHotels?.lastSeenItems.slice(0, resultsPerPage);
};
