import type * as Types from 'graphql/types';

import type { DocumentNode } from 'graphql';
import { ResizedImageWithMetaFragmentDoc } from 'graphql/image/ResizedImageWithMeta.generated';
import { InternalLinkFragmentFragmentDoc } from 'graphql/link/InternalLink.generated';
import type * as Apollo from '@apollo/client';
import * as ApolloReactHooks from '@hotelplan/libs.context.req-ctx';
const defaultOptions = {} as const;
export type GetLastSeenHotelsQueryVariables = Types.Exact<{
  context: Types.RequestContextInput;
  resultsPerPage?: Types.InputMaybe<Types.Scalars['Int']>;
}>;

export type GetLastSeenHotelsQuery = {
  __typename?: 'Query';
  history: {
    __typename?: 'HistoryContext';
    lastSeenHotels: {
      __typename?: 'LastSeenResponse';
      lastSeenItems?: Array<{
        __typename?: 'LastSeenItem';
        productName: string;
        hpRating?: number | null;
        hero?: {
          __typename?: 'Image';
          alt: string;
          resized: Array<{
            __typename?: 'ResizedImage';
            url: string;
            id: string;
          }>;
        } | null;
        link: {
          __typename?: 'InternalLink';
          caption?: string | null;
          uri: string;
          targetPageType: Types.PageType;
        };
        locations?: Array<{
          __typename?: 'GeoObject';
          id: string;
          name: string;
          type: Types.GeoType;
        }> | null;
      }> | null;
    };
  };
};

export const GetLastSeenHotelsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetLastSeenHotels' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'context' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'RequestContextInput' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'resultsPerPage' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          defaultValue: { kind: 'IntValue', value: '3' },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'history' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'context' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'context' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'lastSeenHotels' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'request' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'page' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: 'pageNumber' },
                                  value: { kind: 'IntValue', value: '0' },
                                },
                                {
                                  kind: 'ObjectField',
                                  name: {
                                    kind: 'Name',
                                    value: 'resultsPerPage',
                                  },
                                  value: {
                                    kind: 'Variable',
                                    name: {
                                      kind: 'Name',
                                      value: 'resultsPerPage',
                                    },
                                  },
                                },
                              ],
                            },
                          },
                        ],
                      },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'lastSeenItems' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'productName' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'hpRating' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'hero' },
                              arguments: [
                                {
                                  kind: 'Argument',
                                  name: { kind: 'Name', value: 'criteria' },
                                  value: {
                                    kind: 'ListValue',
                                    values: [
                                      {
                                        kind: 'ObjectValue',
                                        fields: [
                                          {
                                            kind: 'ObjectField',
                                            name: { kind: 'Name', value: 'id' },
                                            value: {
                                              kind: 'StringValue',
                                              value: '1x_desktop',
                                              block: false,
                                            },
                                          },
                                          {
                                            kind: 'ObjectField',
                                            name: {
                                              kind: 'Name',
                                              value: 'width',
                                            },
                                            value: {
                                              kind: 'IntValue',
                                              value: '1024',
                                            },
                                          },
                                          {
                                            kind: 'ObjectField',
                                            name: {
                                              kind: 'Name',
                                              value: 'height',
                                            },
                                            value: {
                                              kind: 'IntValue',
                                              value: '680',
                                            },
                                          },
                                        ],
                                      },
                                    ],
                                  },
                                },
                              ],
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'FragmentSpread',
                                    name: {
                                      kind: 'Name',
                                      value: 'ResizedImageWithMeta',
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'link' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'FragmentSpread',
                                    name: {
                                      kind: 'Name',
                                      value: 'internalLinkFragment',
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'locations' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'name' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'type' },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    ...ResizedImageWithMetaFragmentDoc.definitions,
    ...InternalLinkFragmentFragmentDoc.definitions,
  ],
} as unknown as DocumentNode;

/**
 * __useGetLastSeenHotelsQuery__
 *
 * To run a query within a React component, call `useGetLastSeenHotelsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLastSeenHotelsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLastSeenHotelsQuery({
 *   variables: {
 *      context: // value for 'context'
 *      resultsPerPage: // value for 'resultsPerPage'
 *   },
 * });
 */
export function useGetLastSeenHotelsQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    GetLastSeenHotelsQuery,
    GetLastSeenHotelsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    GetLastSeenHotelsQuery,
    GetLastSeenHotelsQueryVariables
  >(GetLastSeenHotelsDocument, options);
}
export function useGetLastSeenHotelsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    GetLastSeenHotelsQuery,
    GetLastSeenHotelsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    GetLastSeenHotelsQuery,
    GetLastSeenHotelsQueryVariables
  >(GetLastSeenHotelsDocument, options);
}
export type GetLastSeenHotelsQueryHookResult = ReturnType<
  typeof useGetLastSeenHotelsQuery
>;
export type GetLastSeenHotelsLazyQueryHookResult = ReturnType<
  typeof useGetLastSeenHotelsLazyQuery
>;
export type GetLastSeenHotelsQueryResult = Apollo.QueryResult<
  GetLastSeenHotelsQuery,
  GetLastSeenHotelsQueryVariables
>;
