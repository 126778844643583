import { useTranslation } from 'next-i18next';
import React from 'react';
import styled from 'styled-components';
import { Button } from '@hotelplan/components.common.buttons';
import { Icon } from '@hotelplan/components.common.icon';
import { LastSeenHotels } from '@hotelplan/components.common.last-seen-hotels';
import { useDeviceType } from '@hotelplan/libs.context.device-type';
import { sx2CssThemeFn } from '@hotelplan/libs.sx';
import { trackLastSeenHotelClick } from '@hotelplan/libs.tracking';
import { ILastSeenHotelsComponentProps } from './lastSeenHotels.types';

const LastSeenHotelsButton = styled(Button)(
  ({ theme: { buttons } }) => ({
    ...buttons.primary,
    ...buttons.btnWithIcon,
  }),
  sx2CssThemeFn({
    mt: 'auto',
    padding: ['10px 16px', '10px 16px'],
    textTransform: 'uppercase',
    width: 'initial',
    '&:hover': {
      backgroundColor: 'primary',
    },
  })
);

const LastSeenHotelsComponent: React.FC<ILastSeenHotelsComponentProps> = ({
  lastSeenHotels,
}) => {
  const [t] = useTranslation('common');
  const { mobile } = useDeviceType();

  return (
    <LastSeenHotels
      lastSeenHotels={lastSeenHotels}
      title={t('common:lastSeenHotels.title')}
      ratingContent={<Icon name="star-full" />}
      locationsSeparator={' | '}
      trackClick={trackLastSeenHotelClick}
      lastSeenHotelItemFooter={
        <LastSeenHotelsButton
          variant={mobile ? 'iconBtn' : 'primary'}
          icon={{ name: 'chevron-long-right', size: mobile ? 'sm' : 'md' }}
        >
          {t('common:allOffers.btn')}
        </LastSeenHotelsButton>
      }
    />
  );
};

export default LastSeenHotelsComponent;
